import React from "react";
import "aos/dist/aos.css";
import Header from "./components/header";
import Hero from "./components/hero";
import About from "./components/about";
import Clients from "./components/clients";
import Info from "./components/info";
import Footer from "./components/footer";
import Product from "./components/product";
import AOS from "aos";
import { Link } from "react-scroll";
import Team from "./components/team";

function App() {
  React.useEffect(() => {
    AOS.init({
      duration: 1000,
    });
    AOS.refresh();
  }, []);
  return (
    <div className=" overflow-hidden">
      <Header
        navList={[
          { title: "Home", to: "home" },
          { title: "About", to: "about" },
          { title: "Clients", to: "clients" },
          { title: "Products", to: "products" },
          { title: "Services", to: "services" },
        ]}
        rightButton={
          <Link
            className=" px-2  cursor-pointer"
            to="contacts"
            duration={500}
            smooth={true}
            offset={-70}
          >
            <div className="flex gap-x-2  text-sm p-2 items-center justify-center  bg-white text-black">
              Contact Us
            </div>
          </Link>
        }
        navCenter={true}
        logo={{
          item: (
            <div>
              <img src="/white_logo.png" alt="logo" />
            </div>
          ),
          to: "home",
        }}
        styles="bg-[#2f2827]"
      />
      <Hero />
      <About />
      {/* <Clients /> */}
      <hr />
      <Product />
      {/* <BackgroundOne /> */}
      <Info />
      <hr />
      <Team />
      <Footer />
    </div>
  );
}

export default App;
