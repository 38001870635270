import React from "react";
import { IoMdArrowForward } from "react-icons/io";

const Product = () => {
  const [read, setRead] = React.useState(null);

  const readMore = () => {
    return (
      <div className="w-full flex flex-col items-start p-4 lg:p-6 text-black/50 bg-white border border-black/5 rounded">
        <div className="bg-[#2f2827] text-white px-4 py-1 flex">
          Underground support materials
        </div>
        <div className="mt-2">
          <b className="font-black text-black">Welded Mesh</b> - Mining grade -
          All sizes.
        </div>
        <div>
          <b className="font-black text-black">Split sets</b> - galvanised &
          non-galvanised - All Sized.
        </div>
        <div>
          <b className="font-black text-black">Cablebolt Anchors</b> - Active
          support, spin twin strand and single strands.
        </div>
        <div>
          <b className="font-black text-black">Roof bolts</b> - Groutable and
          resin toofbolts - rebairs mining grade.
        </div>
        <div>
          <b className="font-black text-black">Pigtail eyebolts</b> - All sized
          complete with shells.
        </div>
        <div>
          <b className="font-black text-black">Steel arches</b> - All sizes and
          formations.
        </div>
        <div>
          <b className="font-black text-black">Geotechnical Equipment</b> - Pull
          test machine, grout pumps, tensioning machines, cloppers.
        </div>
      </div>
    );
  };

  const readMore2 = () => {
    return (
      <div className="w-full flex flex-col items-start p-4 lg:p-6 text-black/50 bg-white border border-red-500/5 rounded h-[200px] ">
        <div className="bg-[#2f2827] text-white px-4 py-1 flex">
          Mine Safety Equipment
        </div>
        <div className="mt-2">
          Rescue packes; rescue chambers, cap lamps, gas detections instruments,
          alcohol detection instruments, collision avoidance system, seismic
          detection & monitoring equipment e.t.c
        </div>
      </div>
    );
  };

  React.useEffect(() => {
    if (read) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [read]);
  return (
    <div className="flex justify-center " id="products">
      {read && (
        <div className="justify-center items-center backdrop-blur-lg bg-[#2f2827]/20 flex fixed inset-0 z-50">
          <div className="lg:max-w-[1024px] px-4">
            {read}
            <div className="flex justify-start">
              <div
                className="bg-[#2f2827] px-4 py-1 mt-5 cursor-pointer text-white"
                onClick={() => {
                  setRead(null);
                }}
              >
                Close
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="w-full lg:max-w-[1024px] flex flex-col items-center mx-2">
        <div className="text-xl lg:text-3xl bold text-[#907361] my-4">
          Winipam Products Range
        </div>
        <div>
          Our strength is to create procurement solutions tailored to the needs
          and demands of our clientele, aiming to offer a wide range of services
          including but not limited to :
        </div>
        <div className="w-full grid grid-cols-1 lg:grid-cols-3  gap-[1px] my-10">
          <div
            style={{
              backgroundImage: `url("https://kuplakgroup.co.zm/images/pic2.png")`,
              backgroundSize: "cover",
            }}
            className="w-full h-[250px] flex justify-center items-end relative group"
          >
            <div className="bg-white text-black px-4 mb-10 py-1 flex">
              Underground support materials
            </div>
            <div className="w-full absolute top-0 group-hover:p-4 bottom-0 flex-col items-start   text-black/50 bg-white h-0 group-hover:h-[250px] overflow-hidden transition-all duration-500">
              <div className="bg-[#2f2827] text-white px-4 py-1 flex">
                Underground support materials
              </div>
              <div className="mt-2">
                <b className="font-black text-black">Welded Mesh</b> - Mining
                grade - All sizes.
              </div>
              <div>
                <b className="font-black text-black">Split sets</b> - galvanised
                & non-galvanised - ...
              </div>
              <div
                className="flex items-center gap-x-2 text-[#cfb097] cursor-pointer"
                onClick={() => {
                  setRead(readMore);
                }}
              >
                <div>Read More</div>
                <IoMdArrowForward />
              </div>
            </div>
          </div>

          <div
            style={{
              backgroundImage: `url("https://images.unsplash.com/photo-1505833464198-4993b36cdfab?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")`,
              backgroundSize: "cover",
            }}
            className="w-full h-[250px] flex justify-center items-end relative group"
          >
            <div className="bg-white text-black px-4 mb-10 py-1 flex">
              Mine Safety Equipment
            </div>
            <div className="w-full absolute top-0 group-hover:p-4 bottom-0 flex-col items-start   text-black/50 bg-white h-0 group-hover:h-[250px] overflow-hidden transition-all duration-500">
              <div className="bg-[#2f2827] text-white px-4 py-1 flex">
                Mine Safety Equipment
              </div>
              <div className="mt-2">
                Rescue packes; rescue chambers, cap lamps, gas detections
                instruments, alc...
              </div>
              <div
                className="flex items-center gap-x-2 text-[#cfb097] cursor-pointer"
                onClick={() => {
                  setRead(readMore2);
                }}
              >
                <div>Read More</div>
                <IoMdArrowForward />
              </div>
            </div>
          </div>

          <div
            style={{
              backgroundImage: `url("https://www.fanmaster.com.au/wp-content/uploads/Ventilation-Systems-1-2.jpg")`,
              backgroundSize: "cover",
            }}
            className="w-full h-[250px] flex justify-center items-end relative group"
          >
            <div className="bg-white text-black px-4 mb-10 py-1 flex">
              Ventilation Products
            </div>
            <div className="w-full absolute top-0 group-hover:p-4 bottom-0 flex-col items-start   text-black/50 bg-white h-0 group-hover:h-[250px] overflow-hidden transition-all duration-500">
              <div className="bg-[#2f2827] text-white px-4 py-1 flex">
                Ventilation Products
              </div>
              <div className="mt-2">
                Flexible vent duct, vent fans and all accessories
              </div>
            </div>
          </div>

          <div
            style={{
              backgroundImage: `url("https://kuplakgroup.co.zm/images/pic3.png")`,
              backgroundSize: "cover",
            }}
            className="w-full h-[250px] flex justify-center items-end relative group"
          >
            <div className="bg-white text-black px-4 mb-10 py-1 flex">
              Pumps
            </div>
            <div className="w-full absolute top-0 group-hover:p-4 bottom-0 flex-col items-start   text-black/50 bg-white h-0 group-hover:h-[250px] overflow-hidden transition-all duration-500">
              <div className="bg-[#2f2827] text-white px-4 py-1 flex">
                Pumps
              </div>
              <div className="mt-2">
                Submersible pump (Flygt), sulzer, warman slurry pumps.
              </div>
            </div>
          </div>

          <div
            style={{
              backgroundImage: `url("https://kuplakgroup.co.zm/images/pic1.png")`,
              backgroundSize: "cover",
            }}
            className="w-full h-[250px] flex justify-center items-end relative group"
          >
            <div className="bg-white text-black px-4 mb-10 py-1 flex">
              Crushers
            </div>
            <div className="w-full absolute top-0 group-hover:p-4 bottom-0 flex-col items-start   text-black/50 bg-white h-0 group-hover:h-[250px] overflow-hidden transition-all duration-500">
              <div className="bg-[#2f2827] text-white px-4 py-1 flex">
                Crushers
              </div>
              <div className="mt-2">Sandvik and another model.</div>
            </div>
          </div>

          <div
            style={{
              backgroundImage: `url("https://www.kuplakgroup.co.zm/images/power.png")`,
              backgroundSize: "cover",
            }}
            className="w-full h-[250px] flex justify-center items-end relative group"
          >
            <div className="bg-white text-black px-4 mb-10 py-1 flex">
              Electrical items
            </div>
            <div className="w-full absolute top-0 group-hover:p-4 bottom-0 flex-col items-start   text-black/50 bg-white h-0 group-hover:h-[250px] overflow-hidden transition-all duration-500">
              <div className="bg-[#2f2827] text-white px-4 py-1 flex">
                Electrical items
              </div>
              <div className="mt-2">
                Electric motors, electric switchgear & panels, circuit breaker,
                mini sub stations, transformers, generators.
              </div>
            </div>
          </div>

          <div
            style={{
              backgroundImage: `url("https://sika.scene7.com/is/image/sika/glo-shotcrete-05:16-9?wid=1920&hei=1080&fit=crop%2C1")`,
              backgroundSize: "cover",
            }}
            className="w-full h-[250px] flex justify-center items-end relative group"
          >
            <div className="bg-white text-black px-4 mb-10 py-1 flex">
              Grout and shotcrete items
            </div>
            <div className="w-full absolute top-0 group-hover:p-4 bottom-0 flex-col items-start   text-black/50 bg-white h-0 group-hover:h-[250px] overflow-hidden transition-all duration-500">
              <div className="bg-[#2f2827] text-white px-4 py-1 flex">
                Grout and shotcrete items
              </div>
              <div className="mt-2">
                All sized of grout pumps we offer as per the client's range,
                agent of pump rite products.
              </div>
            </div>
          </div>

          <div
            style={{
              backgroundImage: `url("https://flexachem.co.uk/assets/images/page/manualvalves_fp_flexachem_39a-2.png")`,
              backgroundSize: "cover",
            }}
            className="w-full h-[250px] flex justify-center items-end relative group"
          >
            <div className="bg-white text-black px-4 mb-10 py-1 flex">
              Industrial vulves
            </div>
            <div className="w-full absolute top-0 group-hover:p-4 bottom-0 flex-col items-start   text-black/50 bg-white h-0 group-hover:h-[250px] overflow-hidden transition-all duration-500">
              <div className="bg-[#2f2827] text-white px-4 py-1 flex">
                Industrial vulves
              </div>
            </div>
          </div>

          <div
            style={{
              backgroundImage: `url("https://www.hydraulicsontheweb.co.uk/public/shop-images/standard-electric-motor.jpg")`,
              backgroundSize: "cover",
            }}
            className="w-full h-[250px] flex justify-center items-end relative group"
          >
            <div className="bg-white text-black px-4 mb-10 py-1 flex">
              Electic motors
            </div>
            <div className="w-full absolute top-0 group-hover:p-4 bottom-0 flex-col items-start   text-black/50 bg-white h-0 group-hover:h-[250px] overflow-hidden transition-all duration-500">
              <div className="bg-[#2f2827] text-white px-4 py-1 flex">
                Electic motors
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
