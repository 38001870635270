import React from "react";

const Team = () => {
  const team_list = [
    {
      name: "Michel Benzege Mihayo",
      image: "/1.jpg",
      cheo: "Director",
    },
    {
      name: "Paul Shedaffa Kopwe",
      image: "/2.jpg",
      cheo: "Director",
    },
  ];
  return (
    <div className="flex flex-col items-center">
      <div className="lg:max-w-[1024px]">
        <div className="flex justify-center items-center">
          <div className="flex flex-wrap gap-2 py-10 justify-center">
            {team_list.map((item, index) => (
              <div key={index} className="w-[250px] ">
                <div
                  className="w-full h-[260px] relative"
                  style={{
                    backgroundImage: `url(${item.image})`,
                    backgroundPosition: "top",
                    backgroundSize: "cover",
                  }}
                >
                  <div className="w-[200px] h-[25px] p-1 font-bold text-center bg-white left-[25px] absolute bottom-0 z-10">
                    {item.name}
                  </div>
                </div>
                <div className="text-center text-sm my-1">{item.cheo}</div>
              </div>
            ))}
          </div>
        </div>
        <div className=" mt-[10px] mb-10 p-4 lg:py-0">
          <div className="text-xl lg:text-3xl">OUR TEAM</div>
          <ul align="justify" className="list-decimal ml-4 ">
            <li>
              Winipam Mining Solutions limited has two active directors and
              shareholders (Paul Shedaffa Kopwe and Michel Benzege Mihayo)
            </li>
            <li>
              Winipam Minerals Tanzania has a country Manager with a Mining
              engineering degree, one Technical Sales Manager, one Sales
              Engineer with a Mining degree, and Accountant with a degree and an
              Office Administrator with a diploma in Accountancy/Business
              administration.
            </li>
            <li>The company has a total Tanzania staff of five personnel</li>
            <li>
              The company also has a full finance department headed by a CA
              Finance Manager. Winipam also is associated with a well-connected
              and experienced logistics team with a fleet dedicated to serving
              the African mines clients in the best possible way.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Team;
