import React from "react";

const About = () => {
  return (
    <div className="flex flex-col  my-10 items-center float" id="about">
      <div className="grid grid-cols-1 mx-2 md:hidden lg:grid-cols-2 gap-x-4 lg:max-w-[1024px] pb-[20px]">
        <div
          className=" overflow-hidden my-4 md:p-4 lg:my-0"
          data-aos="zoom-in"
        >
          <img
            src="https://images.unsplash.com/photo-1582280776971-087fe7cfa1bd?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="right_image"
          />
        </div>
        <div data-aos="fade-up" className="bg-[#907361]/20 p-4">
          <div className="text-xl lg:text-3xl bold text-[#907361]">
            Introduction
          </div>
          <div align="justify" data-aos="fade-up">
            Winipam is a local Tanzanian Company, specialized in providing
            Solutions and supply of Equipment’s in the Mining and Drilling
            Industry.
            <br />
            <br />
            Our management team have broad experience in both Mining and in many
            other industries. They bring unique qualifications, interests and
            viewpoints to Winipam and its general operations
            <br />
            <br />
            Our strength is to create procurement solutions tailored to the
            needs and demands of our clientele, aiming to offer a wide range of
            services including but not limited to:
          </div>
          <div
            className="bg-[#2f2827] p-4 text-white mt-[20px]"
            data-aos="fade-up"
          >
            Winipam also has business Associates in other countries - South
            Africa, Botswana, Zambia, DRC and Ghana
          </div>
        </div>
      </div>

      <div className="hidden lg:flex items-center mx-2 gap-x-4 max-w-[1024px] pb-[20px]">
        <div
          className=" overflow-hidden  md:p-4 mr-[-100px] w-2/3 z-10"
          data-aos="zoom-in"
        >
          <img
            src="https://images.unsplash.com/photo-1582280776971-087fe7cfa1bd?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="right_image"
          />
        </div>
        <div
          data-aos="fade-up"
          className="bg-[#907361]/20 w-2/3 pl-[100px] pr-[20px] py-[100px]"
        >
          <div className="text-xl lg:text-3xl bold text-[#907361]">
            Introduction
          </div>
          <div align="justify" data-aos="fade-up">
            Winipam is a local Tanzanian Company, specialized in providing
            Solutions and supply of Equipment’s in the Mining and Drilling
            Industry.
            <br />
            <br />
            Our management team have broad experience in both Mining and in many
            other industries. They bring unique qualifications, interests and
            viewpoints to Winipam and its general operations
            <br />
            <br />
            Our strength is to create procurement solutions tailored to the
            needs and demands of our clientele, aiming to offer a wide range of
            services including but not limited to:
          </div>
          <div
            className="bg-[#2f2827] p-4 text-white mt-[20px]"
            data-aos="fade-up"
          >
            Winipam also has business Associates in other countries - South
            Africa, Botswana, Zambia, DRC and Ghana
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 mx-4 lg:mx-2 lg:grid-cols-2 gap-x-4 lg:gap-x-20 lg:max-w-[1024px] pt-[20px]">
        <div>
          <div className="text-xl lg:text-3xl bold text-[#907361]">
            Objectives
          </div>
          <ul className="list-disc ml-[10px]" align="justify">
            <li data-aos="fade-up">
              Winipam aims to bring to the industry the highest professional
              ethical standards and services to the supply and contract business
              in the mining industry regarding the supply and execution of
              general mining materials.
            </li>
            <li data-aos="fade-up" data-aos-delay="100">
              Winipam aims to become a leader and reliable business partner in
              the supply of underground support material and other services to
              the mines in Tanzania
            </li>
            <li data-aos="fade-up" data-aos-delay="200">
              Winipam aims to provide the most competitive prices, highest
              quality and delivery on time for all underground support materials
              in Tanzania
            </li>
            <li data-aos="fade-up" data-aos-delay="300">
              Winipam Mining Solutions limited has an office and warehouse in
              Dar es salaam Tanzania and stocks most underground support
              materials readily within the country
            </li>
            <li data-aos="fade-up" data-aos-delay="400">
              Winipam also intends to be a readily partner in the provision of
              back services support to all mine clients in Tanzania
            </li>
            <li data-aos="fade-up" data-aos-delay="500">
              Winipam’s expertise has been in underground support materials,
              pipes and pumps
            </li>
          </ul>
        </div>
        <div
          className=" overflow-hidden my-4 lg:my-0 pr-[20px]"
          data-aos="zoom-in"
        >
          <img
            src="https://images.unsplash.com/photo-1505833464198-4993b36cdfab?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="right_image"
            className=" "
          />
        </div>
      </div>
    </div>
  );
};

export default About;
