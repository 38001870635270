import React from "react";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer
      className="flex flex-col items-center bg-[#907361]/20"
      id="contacts"
    >
      <div className="lg:max-w-[1024px] my-[10px] lg:mb-[60px]">
        <div className="grid grid-cols-1 ">
          <div className="flex flex-col items-center mt-10">
            <div className="text-lg lg:text-xl text-[#907361] font-bold">
              Winipam Mining Solutions Limited
            </div>
            <div className="text-sm text-center">
              <div className="flex  items-center justify-center">
                <div>+255 747 987 988 / +255 656 709 295</div>
              </div>
              <div className="flex  items-center">
                Info@winipam.com | sales@winipam.com | paulk@winipam.com
              </div>
              <div className="flex  items-center justify-center">
                P.O. BOX 105979 -Dar es salaam - Tanzania
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" bottom-0 text-gray-300 text-sm p-3 flex justify-center  bg-[#2f2827] w-full">
        <div className=" text-sm">© Winipam Copyright {year}.</div>
      </div>
    </footer>
  );
};

export default Footer;
