import React from "react";

const Info = () => {
  return (
    <div
      className="w-full flex flex-col items-center divide-y-[1px] lg:divide-y-0 my-10"
      id="services"
    >
      <div className="w-full bg-[#907361]/20 flex justify-center lg:py-20">
        <div className=" grid grid-cols-1 lg:grid-cols-2 gap-x-4 lg:gap-x-20 lg:max-w-[1024px] mx-4 lg:mx-0 lg:py-[10px]">
          <div className=" overflow-hidden mb-4 lg:mb-0" data-aos="zoom-in">
            <img
              src="https://images.unsplash.com/photo-1582280871722-424e91cbee8b?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt="right_image"
            />
          </div>
          <div className="divide-y-[1px] lg:divide-y-0 divide-black ">
            <div className="py-4 lg:py-0" data-aos="fade-up">
              <div className="text-xl lg:text-3xl ">Pricing</div>
              <ul align="justify" className="list-decimal ml-4 ">
                <li>Our prices are very competitive</li>
                <li>
                  Our prices reflect the true quality of the goods and services
                  we provide hence adding a lot of value to the clients business
                </li>
                <li>
                  Our pricing methodology is guided by professional ethics,
                  credibility and integrity
                </li>
              </ul>
            </div>
            <div
              className=" mt-[10px] py-4 lg:py-0"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="text-xl lg:text-3xl ">Quality</div>
              <ul align="justify" className="list-decimal ml-4 ">
                <li>
                  All the products we supply are certified to various
                  International standards e.g the ISO 9001-2000 e.t.c
                </li>
                <li>
                  The goods we supply are guaranteed for a specified period and
                  after-sales services is provided as well as technical support
                  from our highly qualified engineers
                </li>
                <li>
                  Our products, therefore are normally supplied with quality
                  test certificates
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className=" lg:hidden grid grid-cols-1 lg:grid-cols-2 gap-x-4 lg:py-20 lg:gap-x-20 lg:max-w-[1024px]  mx-4 lg:mx-0   ">
        <div className="divide-y-[1px] lg:divide-y-0 divide-black">
          <div className="py-4 lg:py-0" data-aos="fade-up">
            <div className="text-xl lg:text-3xl">Delivery</div>
            <ul align="justify" className="list-decimal ml-4 ">
              <li>
                Winipam attached great importance to delivery dated for
                efficient operations of our clients mines.
              </li>
              <li>
                We operate a warehouse in Tanzania where the most urgent good on
                contracts are stocked for these markets.
              </li>
              <li>
                We have specific procurement & Logistics office in South Africa
                specifically assigned to timely sourcing, transportation and
                delivery of all goods.We therefore execute our orders on time.
              </li>
            </ul>
          </div>
        </div>
        <div className=" overflow-hidden lg:h-[520px]" data-aos="zoom-in">
          <img
            src="https://images.unsplash.com/photo-1586283970632-8264074606be?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="right_image"
          />
        </div>
      </div>

      <div className="hidden my-[50px] lg:flex items-center mx-2 gap-x-4 max-w-[1024px] ">
        <div
          className=" overflow-hidden  md:p-4 mr-[-100px] w-2/4 z-10 h-[400px] "
          data-aos="zoom-in"
        >
          <img
            src="https://images.unsplash.com/photo-1586283970632-8264074606be?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3Db"
            alt="right_image"
            className="object-cover"
          />
        </div>
        <div className=" w-3/4 bg-[#907361]/20 pl-[100px] py-[50px] pr-[30px]">
          <div className="py-4 lg:py-0" data-aos="fade-up">
            <div className="text-xl lg:text-3xl ">Delivery</div>
            <ul align="justify" className="list-decimal ml-4 ">
              <li>
                Winipam attached great importance to delivery dated for
                efficient operations of our clients mines.
              </li>
              <li>
                We operate a warehouse in Tanzania where the most urgent good on
                contracts are stocked for these markets.
              </li>
              <li>
                We have specific procurement & Logistics office in South Africa
                specifically assigned to timely sourcing, transportation and
                delivery of all goods.We therefore execute our orders on time.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;
