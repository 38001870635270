import React, { useEffect, useState } from "react";
import { CgMenuRightAlt } from "react-icons/cg";
import { Link } from "react-scroll";

const Header = ({ navList, logo, styles, navCenter, rightButton }) => {
  const [navScroll, setNavScroll] = useState(true);
  const [expand, setExpand] = useState(false);

  useEffect(() => {
    window.onscroll = () => {
      if (window.scrollY <= 0) {
        setNavScroll(true);
      } else {
        setNavScroll(true);
      }
    };
  }, []);
  React.useEffect(() => {
    if (expand) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [expand]);

  return (
    <div>
      <header
        className={`w-screen border-gray-600 border-t h-[100px] fixed top-0 py-2 z-20 backdrop-filter ${
          navScroll && "backdrop-blur"
        } flex sm:justify-start md:justify-between items-center ${styles}`}
      >
        <Link
          className=" px-2  cursor-pointer"
          to={logo.to}
          duration={500}
          smooth={true}
          offset={-70}
          onClick={() => setExpand(false)}
        >
          <div className="w-[100px]"> {logo.item}</div>
        </Link>

        {/* opening navigation button */}
        {!expand && (
          <div
            className=" sm:hidden cursor-pointer  absolute right-0 text-white mx-4"
            onClick={() => {
              setExpand(true);
            }}
          >
            <CgMenuRightAlt size={27} />
          </div>
        )}
        {/* opening navigation button */}

        {/* mobile navigations */}
        <div
          className={
            expand
              ? "flex flex-col transition-all duration-500 sm:hidden m-0 w-screen text-xl overflow-hidden h-screen items-center justify-center absolute top-0  bg-[#cfb097]  text-black"
              : "h-0 flex overflow-hidden bg-black bottom-0 transition-all duration-500 w-screen m-0 absolute top-0 delay-200"
          }
        >
          <div
            className={
              expand
                ? "flex flex-col transition-all duration-500 sm:hidden m-0 w-screen delay-200 text-xl overflow-hidden h-screen items-center justify-center absolute top-0  bg-white text-black"
                : "h-0 overflow-hidden bg-black bottom-0 transition-all duration-500 w-screen m-0 absolute top-0"
            }
          />
          <div className="relative w-screen h-screen overflow-hidden flex items-center justify-center">
            <div
              className="py-2 cursor-pointer mt-0 absolute top-0 right-4"
              onClick={() => {
                setExpand(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className={
                  expand
                    ? "h-10 w-10 rotate-0 font-thin transition-all duration-500 delay-200"
                    : "h-0 w-10 rotate-90"
                }
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
            <div className="p-10">
              {navList.map((nav_items, index) => (
                <Link
                  key={index}
                  className={`flex text-3xl my-2 group px-2 flex-col cursor-pointer group  ${nav_items.styles} ${nav_items.styles}`}
                  to={`${nav_items.to}`}
                  duration={500}
                  smooth={true}
                  offset={-70}
                  onClick={() => setExpand(false)}
                >
                  <div
                    className={
                      expand
                        ? "  transition-all duration-500 delay-200 h-8 overflow-hidden"
                        : " block overflow-hidden"
                    }
                  >
                    <div
                      className={
                        expand
                          ? "h-8 transition-all duration-500 translate-y-0 delay-200 overflow-hidden"
                          : "h-8 overflow-hidden -translate-y-10 "
                      }
                    >
                      {nav_items.title}
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
        {/* mobile navigations */}

        {/* desktop navigations */}
        <div
          className={
            navScroll
              ? " hidden sm:block text-white px-2"
              : " hidden sm:block text-black px-2 "
          }
        >
          <div className="flex items-center  mx-4">
            {navList.map((nav_items, index) => (
              <Link
                key={index}
                className={`flex text-sm group px-16 flex-col cursor-pointer group hover:text-[#cfb097] ${nav_items.styles} ${nav_items.styles}`}
                to={`${nav_items.to}`}
                duration={500}
                smooth={true}
                offset={-70}
                onClick={() => setExpand(false)}
              >
                <div className="flex group-hover:font-black">
                  {nav_items.title.toUpperCase()}
                </div>
                <div
                  className="bg-[#cfb097] w-0 transition-all group-hover:w-full"
                  style={{
                    height: 2,
                  }}
                />
              </Link>
            ))}
          </div>
        </div>
        <div
          className={`${
            navCenter || rightButton ? "flex mx-[20px]" : "hidden"
          }`}
        >
          {rightButton ? <>{rightButton}</> : <div className={`w-1 h-1 `} />}
        </div>
        {/* desktop navigations */}
      </header>
    </div>
  );
};

export default Header;
