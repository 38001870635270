import React from "react";

const Hero = () => {
  return (
    <div className="w-full h-[400px] lg:h-[700px] overflow-hidden" id="home">
      <div
        style={{
          backgroundImage: `url(${"https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEiovqyOFHjJcf4od3G0j_D66IcTUmO-sTnCIc4rFT8ToxRypnaj-njpWIS-hHnFeF5_imczbkfB_NbBvyUoKglMVjSNgU8_EL1uB9ImVL3sL3kGOQEK-300sbMH7YKR0YSzxMHhoDwKHoCzptbqyT5XtbBRwUU_AEcGD7S5HZxlpWnk-TS_mG579Ilt7To/s16000/Screenshot%202024-02-06%20at%2012.25.33.png"})`,
          backgroundSize: "cover",
          backgroundPosition: "contain",
          backgroundAttachment: "fixed",
        }}
        className="w-full hidden lg:flex h-full relative  bg-red-900/50"
      >
        <div className="w-full h-full  flex flex-col px-[10px] lg:px-[50px] items-center justify-center "></div>
      </div>

      <div
        style={{
          backgroundImage: `url(${"https://images.unsplash.com/photo-1570979872224-a1ea9f1248b0?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
          backgroundBlendMode: "multiply",
        }}
        className="w-full h-full lg:hidden relative  bg-red-900/50"
      >
        <div className="w-full h-full  flex flex-col px-[10px] lg:px-[50px] items-center justify-center ">
          <div className="text-5xl    text-white">
            WINIPAM &nbsp; MINING &nbsp; SOLUTIONS
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
// https://images.unsplash.com/photo-1570979872224-a1ea9f1248b0?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D
